import React, { useEffect, useRef, useState, useCallback } from "react";
import Control from "./Control";
import * as MS from "../../../styles/MediaStyled";
import * as QS from "../../../styles/QuizStyled";
import * as CS from "../../../styles/CourseViewStyled";
import englishLang from "../../../utils/Languages/english.json";
import {
  convertSecondsToTimeString,
  convertToSeconds,
  formatTime,
  timeStringToSeconds,
} from "../../../utils/Helpers";
import ReactHlsPlayer from "react-hls-player";
import Hls from "hls.js";
import { get } from "lodash";
import axios from "../../../utils/axios";
import { Course, Episode } from "../../../utils/api";
import { Formik, Field, Form, FieldArray } from "formik";
import darkCheckRadio from "../../../assets/images/darkCheckRadio.png";
import checkRadio from "../../../assets/images/checkRadio.png";
import baseRadio from "../../../assets/images/baseRadio.png";
import darkBaseRadio from "../../../assets/images/darkBaseRadio.png";
import { Modal } from "../../../components/PopUp/ModalTwo";
import * as SEC from "../../../styles/SectionStyledTwo";
import successModal from "../../../assets/images/successModal.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setEpisodeId,
  setEpisodeRefresh,
  setErrorPop,
  setErrorPopMsg,
  setQuizEpisode,
  setTimeStamp,
  setVideoPlay,
  setVolumeValue,
} from "../../../store/action";
import ModalComplete from "../../../components/Modal/ModalComplete";
import { debounce } from "lodash";
import greenCheck from "../../../assets/images/greenCheck.png";
import circleCross from "../../../assets/images/circleCross.png";
import CircleLoader from "../../../components/Loader/CircleLoader";
import ReactAudioPlayer from "react-audio-player";
import landt from "../../../assets/images/landt.png";
import rainLogoDarkTwo from "../../../assets/images/rainLogoDarkTwo.png";

const MediaPlayer = ({
  theme,
  handleStartLearning,
  handleIsChanging,
  isChanging,
  handleCourseMenu,
}) => {
  const episodeId = useSelector((state) => state?.episodeId);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const courseId = useSelector((state) => state?.courseId);
  const timeStamp = useSelector((state) => state?.timeStamp);
  const episodeRefresh = useSelector((state) => state?.episodeRefresh);
  const volumeValue = useSelector((state) => state?.volumeValue);
  const selectLang = useSelector((state) => state?.selectLang);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const bannerThumb = useSelector((state) => state?.bannerThumb);
  const hlsRef = useRef(null); // Using useRef to persist hls instance
  const controlRef = useRef(null); // Using useRef to persist hls instance
  const logoAddPlayRef = useRef(null);
  const [qualities, setQualities] = useState([]);
  const [currentQuality, setCurrentQuality] = useState("auto");
  const [quesOne, setQuesOne] = useState(false);
  const [quesTwo, setQuesTwo] = useState(false);
  const [quesThree, setQuesThree] = useState(false);
  const videoPlayerRef = useRef(null);
  const logoPlayRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(volumeValue);
  const [seek, setSeek] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDuration, setIsDuration] = useState(0);
  const [isSamDuration, setIsSamDuration] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSecodo, setTotalSeconds] = useState(0);
  const mouseMoveTimeout = useRef(null);
  const [quizData, setQuizData] = useState(null);
  const [filterQuizData, setFilterQuizData] = useState([]);
  const [courseEpisodeData, setCourseEpisodeData] = useState([]);
  const languageId = useSelector((state) => state?.languageId);
  const [isPopPo, setIsPopPo] = useState(false);
  const [stopTimeStamp, setStopTimeStamp] = useState(null);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [showsucess, setShowsucess] = useState(true);
  const [successMsg, setSuccessMsg] = useState(selectLangJson.episode_complete);
  const [apiCalled, setApiCalled] = useState(false);
  const [quizId, setQuizId] = useState("");
  const [nextEpisode, setNextEpisode] = useState("");
  const [quizShown, setQuizShown] = useState(false);
  const [isLogoPlay, setIsLogoPlay] = useState(false);
  const [isLogoAddDuration, setIsLogoAddDuration] = useState(null);
  const debouncedNameFunction = useRef();
  const episodeIdRef = useRef(episodeId);
  const [answered, setAnswered] = useState(
    new Array(filterQuizData?.question?.length)?.fill(false)
  );
  const profileData = useSelector((state) => state?.profileData);
  const [videoSrc, setVideoSrc] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderEpisode, setIsLoaderEpisode] = useState(false);
  const [isLoaderAdd, setIsLoaderAdd] = useState(false);
  const [isAddMuted, setIsAddMuted] = useState(true);
  const [isAddArr, setIsAddArr] = useState([]);
  const [isCourseComplete, setIsCourseComplete] = useState({});
  const [isLogoAddPlay, setIsLogoAddPlay] = useState(false);
  const [addLogoUrl, setAddLogoUrl] = useState("");
  const [isMusicShow, setIsMusicShow] = useState(false);
  const [maxWatchedTime, setMaxWatchedTime] = useState(0);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );

  useEffect(() => {
    episodeIdRef.current = episodeId;
  }, [episodeId]);

  const handleMetadata = (e) => {
    setIsLoaderEpisode(false);
    setIsDuration(formatTime(e.target.duration));
    setIsSamDuration(e.target.duration);
    setIsLogoAddDuration(Math.floor(e.target.duration));
    const savedVolume =
      parseFloat(localStorage.getItem("rainLearnerVolume")) || 0.5; // Default to 50% if not set
    setVolume(savedVolume);
    videoPlayerRef.current.volume = savedVolume;
  };
  const handleAddMetadata = (e) => {
    setIsLoaderAdd(false);
    if (audioRef.current) {
      audioRef.current.audioEl.current.play();
    }
  };
  const startLearning = async () => {
    if (document.fullscreenElement) {
      try {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen(); // Safari
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen(); // IE11
        }
      } catch (error) {
        console.error("Error exiting fullscreen:", error);
      }
    }
    let url = Episode.episodeComplete;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        episodeId: episodeId,
        languageId: episodeSelectLang,
      });
      setIsPopPo(true);
      // setTimeout(() => {
      //   setIsPopPo(false);
      // }, 2000);
      setIsPlaying(false);
      if (data?.data) {
        setQuizId(data?.data?.quiz?._id);
      }
      if (data?.data?.nextEpisodeId) {
        setNextEpisode(data?.data?.nextEpisodeId);
      }

      setIsCourseComplete(data?.data);
      if (data?.data?.COURSE_COMPLETED) {
        handleStartLearning();
        handleCourseMenu();
        if (data?.data?.VIEW_POLICY_DOCUMENTS) {
          navigate(`${dynamicRoutes}/learner/courses/policy-document`);
        }
      }
      setQuizData({});
      // if (data?.data?.NEXT_EPISODES) {
      //   dispatch(setVideoPlay(true));
      // }
      // handleStartLearning();
      dispatch(setEpisodeRefresh(episodeRefresh + 1));
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setVideoPlay(false));
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };
  const [isNameFunctionCalled, setIsNameFunctionCalled] = useState(false);

  const nameFuntion = async (e) => {
    if (isNameFunctionCalled) return;
    setIsNameFunctionCalled(true);
    let url = Episode.timeSubmit;
    const formValues = {
      courseId: courseId,
      episodeId: episodeId,
      languageId: episodeSelectLang,
      last_time_stamp: convertSecondsToTimeString(e),
    };

    try {
      const { data } = await axios.post(url, formValues);
      if (videoPlayerRef.current) {
        const currentTime = videoPlayerRef.current.currentTime || 0;
        const duration = videoPlayerRef.current.duration || 0;
        if (
          duration > 0 &&
          !apiCalled &&
          Math.abs(duration - currentTime) < 0.5
        ) {
          startLearning();
        }
      }
    } catch (error) {
      dispatch(setVideoPlay(false));
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    } finally {
      setIsNameFunctionCalled(false);
      setApiCalled(false);
    }
  };
  const handlePlay = () => {
    var playPromise = videoPlayerRef?.current?.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // videoPlayerRef?.current?.play();
          setIsPlaying(true);
          setIsLoaderAdd(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePause = () => {
    videoPlayerRef?.current?.pause();
    setIsPlaying(false);
    if (currentTime > 2) {
      // getCourseEpisodeData();
      nameFuntion(currentTime);
    }
  };

  const toggleMute = () => {
    const newMuteStatus = !isMuted;
    setIsMuted(newMuteStatus);
    videoPlayerRef.current.muted = newMuteStatus;
  };

  const volumeChangeHandler = (event) => {
    const newVolume = parseFloat(event.target.value) / 100;
    setVolume(newVolume);
    dispatch(setVolumeValue(newVolume));
    localStorage.setItem("rainLearnerVolume", newVolume);
    videoPlayerRef.current.volume = newVolume;
  };
  const handleTimeUpdate = () => {
    if (videoPlayerRef.current) {
      const currentTime = videoPlayerRef.current.currentTime || 0;
      const duration = videoPlayerRef.current.duration;
      const seekValue = (currentTime / duration) * 100;
      setCurrentTime(currentTime);
      setSeek(seekValue); // Update the seek state
      if (duration > 0 && !apiCalled && duration === currentTime) {
        setApiCalled((apiCalled) => {
          if (!apiCalled) {
            debouncedNameFunction.current(currentTime);
            return true;
          }
          return apiCalled;
        });
      }
    }
  };

  useEffect(() => {
    console.log(timeStamp, "nameFuntion nameFuntion nameFuntionnameFuntionnameFuntion")
    debouncedNameFunction.current = debounce(
      (currentTime) => {
        nameFuntion(currentTime, episodeId);
        setIsAddArr([]);
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    );

    return () => {
      debouncedNameFunction.current.cancel();
    };
  }, [episodeId, timeStamp]);
  // For Admin
  const handleSeekAdmin = (event) => {
    const newSeek = parseInt(event.target.value, 10);
    setSeek(newSeek);
    const seekTime = (newSeek / 100) * videoPlayerRef.current.duration;
    videoPlayerRef.current.currentTime = seekTime;
    setIsDuration(formatTime(videoPlayerRef.current.duration));
  };
  // For Learner
  const handleSeek = (event) => {
    const newSeekValue = parseInt(event.target.value, 10); // Get the percentage of duration from the slider
    const newSeekTime = (newSeekValue / 100) * videoPlayerRef.current.duration; // Convert percentage to seconds

    if (newSeekTime <= maxWatchedTime) {
      videoPlayerRef.current.currentTime = newSeekTime;
      setSeek(newSeekValue); // Update the slider position
    } else {
      const maxSeekValue =
        (maxWatchedTime / videoPlayerRef.current.duration) * 100; // Convert max watched time to percentage
      setSeek(maxSeekValue); // Update the slider to reflect the max watched time
      videoPlayerRef.current.currentTime = maxWatchedTime; // Set the video time to max watched time
    }

    setIsDuration(formatTime(videoPlayerRef.current.duration)); // Optional: If you need to display the duration
  };

  const updateTime = () => {
    if (videoPlayerRef.current) {
      const currentTime = videoPlayerRef.current.currentTime;

      // Update maxWatchedTime if the current time is greater
      if (currentTime > maxWatchedTime) {
        setMaxWatchedTime(currentTime);
      }

      // Optionally sync the slider position based on the currentTime
      const currentSeekValue =
        (currentTime / videoPlayerRef.current.duration) * 100;
      setSeek(currentSeekValue);
    }
  };

  // Add event listener for time updates in useEffect
  useEffect(() => {
    const player = videoPlayerRef.current;

    // Attach timeupdate listener to update max watched time and sync seek bar
    player.addEventListener("timeupdate", updateTime);

    return () => {
      // Clean up the event listener
      player.removeEventListener("timeupdate", updateTime);
    };
  }, [maxWatchedTime]);

  useEffect(() => {
    if (Hls.isSupported()) {
      const video = videoPlayerRef.current;
      const hls = new Hls({
        maxLoadingDelay: 4,
        maxBufferLength: 5,
        maxMaxBufferLength: 10,
        maxBufferSize: 10 * 1000 * 1000,
        maxBufferHole: 0.1,
        lowBufferWatchdogPeriod: 0.5,
        highBufferWatchdogPeriod: 3,
        enableWorker: true,
        liveSyncDurationCount: 3,
      });
      hlsRef.current = hls;
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      setHlsInstance(hls);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        setQualities(hls.levels);
        setCurrentQuality(hls.currentLevel);
      });
      hls.on(Hls.Events.LEVEL_SWITCHED, (_, data) => {
        setCurrentQuality(data.level);
      });
      // hls.on(Hls.Events.FRAG_LOADING, (_, data) => {
      //   const startTime = performance.now();
      //   hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
      //     const latency = performance.now() - startTime;
      //     setPing(latency);

      //     // Access the stats correctly
      //     const fragmentStats = data.frag.stats;
      //     setDataUsed((prevData) => prevData + fragmentStats.loaded);
      //   });
      // });
      // hls.on(Hls.Events.ERROR, function (event, data) {
      //   const errorType = data.type;
      //   const errorDetails = data.details;
      //   const errorFatal = data.fatal;

      //   console.error(
      //     `Error: ${errorType}, Details: ${errorDetails}, Fatal: ${errorFatal}`
      //   );
      // });

      return () => {
        hls.destroy();
      };
    } else if (
      videoPlayerRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoPlayerRef.current.src = videoSrc;
    }
  }, [videoSrc]);

  const handleQualityChange = (levelIndex) => {
    if (hlsInstance) {
      const currentTime = videoPlayerRef.current.currentTime || 0;
      const isPaused = videoPlayerRef.current.paused;
      hlsInstance.currentLevel = levelIndex;
      hlsInstance.startLoad();
      hlsInstance.once(Hls.Events.FRAG_LOADED, () => {
        videoPlayerRef.current.currentTime = currentTime;
        if (!isPaused) {
          videoPlayerRef?.current?.play();
        }
      });
      setCurrentQuality(levelIndex);
    }
  };
  const checkQuiz = useCallback(() => {
    const fixedCurrentTime = Math.floor(currentTime);
    let filterQuiz = quizData?.question?.filter(
      (val) => val.totalSeconds === fixedCurrentTime
    );

    if (filterQuiz?.length > 0 && !quizShown) {
      setFilterQuizData({ question: filterQuiz });
      videoPlayerRef?.current?.pause();
      setIsPlaying(false);
      nameFuntion(videoPlayerRef?.current?.currentTime);
      setQuesOne(true);
      setIsLoaderEpisode(false);
      setQuizShown(true);
    } else if (!filterQuiz?.length && quizShown) {
      setQuizShown(false); // Reset the quizShown state when no relevant quiz is found
    }
  }, [currentTime, quizData, quizShown]);
  useEffect(() => {
    const debouncedCheckQuiz = debounce(checkQuiz, 100);

    debouncedCheckQuiz();

    return () => {
      debouncedCheckQuiz?.cancel();
    };
  }, [currentTime, quizData, quizShown, checkQuiz]); // Include checkQuiz as a dependency here

  const toggleFullScreen = () => {
    const videoElement = controlRef.current.closest(".media-class-box"); // Reference to your video player

    if (!document.fullscreenElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen(); // Standard way to request fullscreen
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen(); // Safari
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen(); // IE11
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen(); // Exit fullscreen
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
      }
    }
  };
  const handleMouseOver = () => {
    if (!controlsVisible) {
      mouseMoveTimeout.current = setTimeout(() => {
        setControlsVisible(false);
      }, 3000);
    }
    // handleMouseMove(); // Call mouse move to reset the timer as soon as mouse is over
  };

  const handleMouseMove = () => {
    setControlsVisible(true);
    clearTimeout(mouseMoveTimeout.current);
    mouseMoveTimeout.current = setTimeout(() => {
      setControlsVisible(false);
    }, 3000);
  };

  const handleMouseLeave = () => {
    clearTimeout(mouseMoveTimeout.current);
    setControlsVisible(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(mouseMoveTimeout.current);
    };
  }, []);
  const videoTimeConvert = (e, time, endTime) => {
    if (e === 1) {
      return 0;
    } else if (e === 3) {
      return endTime - 1;
    } else {
      return convertToSeconds(time);
    }
  };
  const getCourseEpisodeData = async () => {
    let url = Episode.userEpisodeWatchInfo;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        episodeId: episodeId,
        languageId: episodeSelectLang,
      });
      if (data?.data) {
        setCourseEpisodeData(data?.data);
        const timeStr = get(data?.data, "last_time_stamp", "00:00:00");
        const parts = timeStr?.split(":");

        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseInt(parts[2], 10);
        setNextEpisode("");
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        if (totalSeconds) {
          setTotalSeconds(totalSeconds);
          setCurrentTime(totalSeconds);
        }
        setStopTimeStamp(get(data?.data, "stop_time_stamp", null));
        if (data?.data?.url) {
          setVideoSrc(get(data?.data, "url", ""));
          setLogoUrl(get(data?.data, "logoUrl", ""));
          setIsLogoPlay(get(data?.data, "logoPlay", false));
          if (get(data?.data, "logoPlay")) {
            setIsLoader(true);
          }
          dispatch(setVideoPlay(true));
        } else {
          dispatch(setVideoPlay(false));
        }
        setApiCalled(false);
        dispatch(setQuizEpisode(false));
        const upcomingQuizQuestions = get(
          data?.data,
          "upcoming_quiz[0].questions",
          []
        );
        setAnswered(new Array(upcomingQuizQuestions?.length)?.fill(false));
        if (upcomingQuizQuestions.length > 0) {
          setQuizData({
            question: upcomingQuizQuestions,
          });
        }
        if (data?.data?.video?.length || data?.data?.slide_with_music?.length) {
          let mergeAddData = [
            ...data?.data?.video,
            ...data?.data?.slide_with_music,
          ];
          if (mergeAddData?.length > 0) {
            const timeStr = get(data?.data, "end_time_stamp", "00:00:00");
            const parts = timeStr?.split(":");

            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);
            const seconds = parseInt(parts[2], 10);
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            setIsAddArr(
              mergeAddData?.map((val, ind) => {
                return {
                  id: ind + 1,
                  src: val?.convert_url,
                  totalTime: videoTimeConvert(
                    val.placementType,
                    val.time,
                    totalSeconds
                  ),
                  musicId: val.musicId ? val.musicId : null,
                  musicUrl: val.url ? val.url : null,
                };
              })
            );
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data?.data?.HOME_SCREEN) {
          dispatch(setVideoPlay(false));
        }
      }
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      dispatch(setVideoPlay(false));
    }
  };

  useEffect(() => {
    if (courseId && episodeId) {
      getCourseEpisodeData();
    }
  }, [courseId, episodeId]);
  const handleQuizSubmit = async (values) => {
    setIsLoading(true);
    let url = Episode.interactiveQuizSubmit;
    let formValues = {
      courseId: courseId,
      episodeId: episodeId,
      languageId: episodeSelectLang,
      QuizId: get(values, "question[0]._id", ""),
      answerOptionsId: values?.question[0]?.answerOptions?.find(
        (val) => val.isAnswers
      )?._id,
    };

    try {
      const { data } = await axios.post(url, formValues);
      nameFuntion((videoPlayerRef.current.currentTime += 1));
      setQuesOne(false);
      setIsLoading(false);
      setIsPlaying(true);
      setFilterQuizData([]);
      getCourseEpisodeData();
      handlePlay();
    } catch (error) {
      console.log(error.response.data.message);
      setIsLoading(false);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    const player = videoPlayerRef.current;
    if (player) {
      player.currentTime = totalSecodo;
    }
  }, [totalSecodo]);

  useEffect(() => {
    const player = videoPlayerRef.current;
    if (player) {
      const handleLoadedMetadata = (e) => {
        handleMetadata(e);
      };
      player.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        player.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, []);
  const handleClose = () => {
    setIsPopPo(false);
  };
  useEffect(() => {
    if (videoSrc) {
      handlePlay();
    }
  }, [videoSrc]);

  const restartEpisode = async () => {
    let url = Episode.restartEpisode;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        episodeId: episodeId,
        languageId: episodeSelectLang,
      });
      dispatch(setQuizEpisode(true));
      if (videoPlayerRef.current) {
        videoPlayerRef.current.currentTime = 0;
        handlePlay();
      }
      setIsPopPo(false);
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  const getCorrectAnswer = (e) => {
    let correctAnwser = e?.find(
      (val) => val.isAnswers === true && val.isAnswer === true
    );
    if (correctAnwser) {
      return (
        <QS.DesciptionContainer themeColor={theme}>
          <QS.CorrectRow themeColor={theme}>
            <img src={greenCheck} alt="" />
            <p>{selectLangJson.correct_answer}</p>
          </QS.CorrectRow>
          {correctAnwser?.description ? (
            <QS.DesciptionBox themeColor={theme}>
              <p className="title">{selectLangJson.description} :</p>
              <p className="desc">{correctAnwser?.description}</p>
            </QS.DesciptionBox>
          ) : (
            ""
          )}
        </QS.DesciptionContainer>
      );
    } else {
      let wrongAnswer = e?.find(
        (val) => val.isAnswers === false && val.isAnswer === true
      );
      if (wrongAnswer) {
        return (
          <QS.DesciptionContainer themeColor={theme}>
            <QS.CorrectRow wrongAns={true}>
              <img src={circleCross} alt="" />
              <p>{selectLangJson.incorrect_answer}</p>
            </QS.CorrectRow>
            {wrongAnswer?.description ? (
              <QS.DesciptionBox themeColor={theme}>
                <p className="title">{selectLangJson.description} :</p>
                <p className="desc">{wrongAnswer?.description}</p>
              </QS.DesciptionBox>
            ) : (
              ""
            )}
          </QS.DesciptionContainer>
        );
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden || !document.hasFocus()) {
        handlePause();
        setIsPlaying(false);
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleVisibilityChange);
    window.addEventListener("focus", () => {
      // Optionally resume playback only if certain conditions are met
      if (!document.hidden && document.hasFocus()) {
        handlePause();
        setIsPlaying(false);
      }
    });

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleVisibilityChange);
      window.removeEventListener("focus", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (quesOne) {
      handlePause();
    }
  }, [quesOne]);

  const handleVideoEnd = () => {
    if (currentURL === logoUrl) {
      // setIsLogoPlay(false)
    } else {
      // Optionally reset to the first video or handle end of playlist
      setIsLogoPlay(false);
      setIsPlaying(true);
      setIsLoaderEpisode(true);
      console.log("End of playlist");
    }
  };

  const handleVideoReady = () => {
    setIsLoader(false); // Set loading to false when video is ready
  };

  // Add Video Between Episode
  let mp3Url =
    "https://s3rain.s3.ap-south-1.amazonaws.com/1716374923670_1714108715462_sample-12s.mp3";
  const [musicSrc, setMusicSrc] = useState(null);
  const audioRef = useRef(null);

  // useEffect(() => {
  //   if (activeSrc === mp3Url) {
  //     audioRef.current.audioEl.current.play();
  //   } else {
  //     audioRef.current.audioEl.current.pause();
  //   }
  // }, [activeSrc, src]);

  const handlePlayMusic = () => {
    setMusicSrc(musicSrc);
  };
  const handlePlayAddLogo = () => {
    const videoElement = logoAddPlayRef.current;
    if (videoElement && videoElement.readyState >= 3) {
      // 3 = HAVE_FUTURE_DATA
      var playPromises = videoElement.play();
      if (playPromises !== undefined) {
        playPromises
          .then(() => {
            console.log("Video is playing after checking readyState");
            setIsLogoAddPlay(false);
            setIsLoaderAdd(true);
          })
          .catch((error) => {
            console.log("Error playing video:", error);
          });
      }
    } else {
      console.log("Video not ready to play");
      // Optionally set up an event listener for 'canplay' or 'loadeddata'
    }
  };
  const handleLoad = () => {
    var playPromise = logoAddPlayRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          console.log("Video is playing after load");
          setIsLogoAddPlay(true);
          setIsLoaderAdd(false);
        })
        .catch((error) => {
          console.error("Error playing video after load:", error);
        });
    }
  };
  useEffect(() => {
    const videoElement = logoAddPlayRef.current;
    if (videoElement) {
      videoElement.addEventListener("canplay", handleLoad);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("canplay", handleLoad);
      }
    };
  }, [logoAddPlayRef]);
  const checkQuizAdd = useCallback(() => {
    // if (Math.floor(currentTime) > 0) {
    const fixedCurrentTime = Math.floor(currentTime);
    let filterQuiz = isAddArr?.filter(
      (val) => val.totalTime === fixedCurrentTime
    );
    if (filterQuiz?.length > 0 && !isLogoAddPlay) {
      var playPromise = videoPlayerRef?.current?.pause();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {})
          .catch((error) => {
            console.log(error);
          });
      }
      if (filterQuiz[0]?.musicId) {
        setMusicSrc(filterQuiz[0]?.musicUrl);
        setIsMusicShow(true);
        setIsAddMuted(true);
      } else {
        setIsAddMuted(false);
      }
      setAddLogoUrl(filterQuiz[0]?.src);
      handlePlayAddLogo();
      setIsPlaying(false);
      setIsLoaderAdd(true);
      setIsLogoAddPlay(true);
      setIsLoaderEpisode(false);
      nameFuntion(videoPlayerRef?.current?.currentTime);
    } else if (!filterQuiz?.length && isLogoAddPlay) {
      setIsLogoAddPlay(false);
    }
    // }
  }, [currentTime, isLogoAddPlay]);
  useEffect(() => {
    const debouncedCheckQuiz = debounce(checkQuizAdd, 100);

    debouncedCheckQuiz();

    return () => {
      debouncedCheckQuiz?.cancel();
    };
  }, [currentTime, isLogoAddPlay, checkQuizAdd]);

  const handleAddVideoEnd = () => {
    setIsLogoAddPlay(false);
    setIsLoaderAdd(false);
    setIsLoaderEpisode(false);
    setAddLogoUrl("");
    setMusicSrc(null);
    if (audioRef.current) {
      audioRef.current.audioEl.current.pause();
    }
    setIsMusicShow(false);
    if (videoPlayerRef && videoPlayerRef.current) {
      handlePlay();
      setIsPlaying(true);
      setIsLoader(false);
      videoPlayerRef.current.currentTime = Math.floor(currentTime) + 1;
    }
  };
  useEffect(() => {
    if (!isLoaderAdd && !isLogoAddPlay) {
      handlePlay();
      setIsMusicShow(false);
    }
  }, [isLoaderAdd, isLogoAddPlay]);

  useEffect(() => {
    console.log(currentTime, "currentTime currentTime")
    if(currentTime > 1){
      dispatch(setTimeStamp(currentTime))
    }
    
  }, [currentTime])
  return (
    <>
      <MS.MediaContainer>
        <MS.MediaInner
          onMouseOver={handleMouseOver}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          className="media-class-box"
        >
          {isLoader ? <CircleLoader themeColor={theme} /> : ""}
          {isLogoPlay ? (
            <ReactHlsPlayer
              style={{ display: isLoader ? "none" : "flex" }}
              className="video-class-element"
              playerRef={logoPlayRef}
              src={logoUrl}
              muted={true}
              autoPlay={true}
              volume={volume}
              controls={false}
              onEnded={handleVideoEnd}
              onLoadedMetadata={handleVideoReady}
            />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  visibility: isLogoAddPlay ? "hidden" : "visible",
                  position: "absolute",
                  height: "100%",
                }}
              >
                {isLoaderEpisode ? <CircleLoader themeColor={theme} /> : ""}
                {!controlsVisible && (
                  <MS.EpisodeNameRow
                    style={{ display: isLoaderEpisode ? "none" : "flex" }}
                  >
                    <MS.FirstHeader themeColor={theme}>
                      <p className="title">{courseEpisodeData?.episodeName}</p>
                    </MS.FirstHeader>
                  </MS.EpisodeNameRow>
                )}
                {bannerThumb?.client_logo?.logoStatus && (
                  <MS.ClientLogo>
                    <img
                      src={bannerThumb?.client_logo?.logo?.logoImage}
                      // src={landt}
                      alt="Logo"
                    />
                  </MS.ClientLogo>
                )}

                <ReactHlsPlayer
                  style={{ display: isLoaderEpisode ? "none" : "flex" }}
                  className="video-class-element"
                  playerRef={videoPlayerRef}
                  src={videoSrc}
                  muted={isMuted}
                  autoPlay={true}
                  volume={volume}
                  onLoadedMetadata={handleMetadata}
                  onTimeUpdate={handleTimeUpdate}
                  controls={false}
                />
                {controlsVisible &&
                  (!isLoaderEpisode ? (
                    <Control
                      onPlay={handlePlay}
                      onPause={handlePause}
                      playing={isPlaying}
                      onVolumeChange={volumeChangeHandler}
                      volume={volume * 100}
                      handleMute={toggleMute}
                      isMuted={isMuted}
                      seek={seek}
                      handleNothing={handleSeekAdmin}
                      handleSeekChange={handleSeek}
                      duration={isDuration}
                      currentTime={formatTime(currentTime)}
                      qualities={qualities}
                      handleQualityChange={handleQualityChange}
                      currentQuality={currentQuality}
                      toggleFullScreen={toggleFullScreen}
                      controlRef={controlRef}
                      handleProgress={profileData?.switchToUser}
                    />
                  ) : (
                    ""
                  ))}
              </div>

              {isLoaderAdd ? <CircleLoader themeColor={theme} /> : ""}
              <ReactHlsPlayer
                style={{
                  visibility: isLogoAddPlay ? "visible" : "hidden",
                  display: isLoaderAdd ? "none" : "flex",
                }}
                className="add-video-class-element"
                playerRef={logoAddPlayRef}
                src={addLogoUrl}
                muted={isAddMuted}
                autoPlay={true}
                controls={false}
                onLoadedMetadata={handleAddMetadata}
                onEnded={handleAddVideoEnd}
              />
              {isMusicShow ? (
                <MS.MusicBox style={{ visibility: "hidden" }}>
                  <ReactAudioPlayer
                    ref={audioRef}
                    src={musicSrc}
                    controls
                    loop={true}
                    onPlay={handlePlayMusic}
                  />
                </MS.MusicBox>
              ) : (
                ""
              )}
            </>
          )}

          {quesOne ? (
            <MS.InnerFirstTwos themeColor={theme}>
              <Formik
                enableReinitialize
                initialValues={filterQuizData}
                // validate={learnerClientValidator}
                validateOnChange
                onSubmit={(values) => handleQuizSubmit(values, false)}
              >
                {(formikBag) => (
                  <Form style={{ width: "100%" }}>
                    <QS.QuestionContainer themeColor={theme}>
                      <FieldArray name="question">
                        {({ form }) => {
                          return (
                            formikBag?.values.question?.length > 0 &&
                            formikBag?.values.question?.map((val, ind) => {
                              // const correcAns =
                              //   val?.answerOptions?.filter(
                              //     (opt) =>
                              //       opt.isAnswer === true &&
                              //       opt.isAnswers === true
                              //   ) || [];
                              // const wrongAns =
                              //   val?.answerOptions?.filter(
                              //     (opt) =>
                              //       opt.isAnswer === true &&
                              //       opt.isAnswers === false
                              //   ) || [];

                              return (
                                <QS.QuestionContainer
                                  key={ind}
                                  themeColor={theme}
                                >
                                  <QS.InnerQuestionCon themeColor={theme}>
                                    <QS.QuestionTitle themeColor={theme}>
                                      <p>
                                        {ind + 1}. {val.question}
                                      </p>
                                    </QS.QuestionTitle>
                                    {/* {val?.answerOptions?.map(
                                    (innerVal, index) => {
                                      return (
                                        <QS.QuestionOption
                                          key={index}
                                          themeColor={theme}
                                        >
                                          <img
                                            onClick={() => {
                                              if (!answered[index]) { // Only allow clicking if not already answered
                                                const questionValuesPath = `question.${ind}.answerOptions`;
                                                const updatedQuestionValues = form?.values?.question[ind]?.answerOptions?.map((opt, idx) => ({
                                                  ...opt,
                                                  isAnswers: idx === index,
                                                }));
                    
                                                formikBag.setFieldValue(questionValuesPath, updatedQuestionValues);
                                                let newAnswered = [...answered];
                                                newAnswered[index] = true; // Set this question as answered
                                                setAnswered(newAnswered);
                                              }
                                            }}
                                            style={{ cursor: answered[index] ? 'not-allowed' : 'pointer' }}
                                            src={
                                              innerVal.isAnswers
                                                ? theme
                                                  ? darkCheckRadio
                                                  : checkRadio
                                                : theme
                                                ? darkBaseRadio
                                                : baseRadio
                                            }
                                            alt=""
                                          />
                                          <p>{innerVal.option}</p>
                                        </QS.QuestionOption>
                                      );
                                    }
                                  )} */}
                                    {val?.answerOptions?.map(
                                      (innerVal, answerIndex) => {
                                        return (
                                          <QS.QuestionOption
                                            key={answerIndex}
                                            themeColor={theme}
                                          >
                                            <img
                                              onClick={() => {
                                                if (!answered[ind]) {
                                                  // Check if the question at the current index `ind` has not been answered
                                                  const questionValuesPath = `question.${ind}.answerOptions`;
                                                  const updatedQuestionValues =
                                                    formikBag.values.question[
                                                      ind
                                                    ].answerOptions.map(
                                                      (opt, idx) => ({
                                                        ...opt,
                                                        isAnswers:
                                                          idx === answerIndex, // Mark the clicked answer
                                                      })
                                                    );

                                                  formikBag.setFieldValue(
                                                    questionValuesPath,
                                                    updatedQuestionValues
                                                  );
                                                  let newAnswered = [
                                                    ...answered,
                                                  ];
                                                  newAnswered[ind] = true; // Mark the question as answered in the 'answered' array
                                                  setAnswered(newAnswered);
                                                }
                                              }}
                                              style={{
                                                cursor: answered[ind]
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }} // Use the question's index `ind` to check if it's answered
                                              src={
                                                innerVal.isAnswers
                                                  ? theme
                                                    ? darkCheckRadio
                                                    : checkRadio
                                                  : theme
                                                  ? darkBaseRadio
                                                  : baseRadio
                                              }
                                              alt=""
                                            />
                                            <p>{innerVal.option}</p>
                                          </QS.QuestionOption>
                                        );
                                      }
                                    )}
                                  </QS.InnerQuestionCon>
                                  {getCorrectAnswer(val.answerOptions)}
                                </QS.QuestionContainer>
                              );
                            })
                          );
                        }}
                      </FieldArray>
                      <QS.ButtonGroup themeColor={theme}>
                        {/* <QS.CancelButton
                        themeColor={theme}
                        type="button"
                        onClick={() => {
                          setIsPopPo(true);
                        }}
                      >
                        Cancel
                      </QS.CancelButton> */}
                        {answered[0] ? (
                          <QS.SaveButton
                            cursor={isLoading}
                            disabled={isLoading}
                            className="buttonload"
                            type="submit"
                          >
                            {isLoading ? (
                              <i class="fa fa-spinner fa-spin"></i>
                            ) : (
                              selectLangJson.submit_continue
                            )}
                          </QS.SaveButton>
                        ) : (
                          ""
                        )}
                      </QS.ButtonGroup>
                    </QS.QuestionContainer>
                  </Form>
                )}
              </Formik>
            </MS.InnerFirstTwos>
          ) : (
            ""
          )}
        </MS.MediaInner>
      </MS.MediaContainer>
      <ModalComplete
        isOpen={isPopPo}
        maxWidth="400px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson.great}!
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>{successMsg}</SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "300px" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: quizId ? "50%" : "100%" }}
                themeColor={theme}
                onClick={() => {
                  restartEpisode();
                }}
              >
                {selectLangJson.restart_episode}
              </QS.CancelButton>
              {quizId ? (
                <QS.SaveButton
                  style={{ width: "50%" }}
                  themeColor={theme}
                  onClick={() => {
                    navigate(`${dynamicRoutes}/learner/quiz`, {
                      state: {
                        _id: quizId,
                      },
                    });
                  }}
                >
                  {selectLangJson.take_quiz}
                </QS.SaveButton>
              ) : (
                ""
              )}
              {nextEpisode ? (
                <QS.SaveButton
                  style={{ width: "50%" }}
                  themeColor={theme}
                  onClick={() => {
                    dispatch(setVideoPlay(true));
                    dispatch(setEpisodeId(nextEpisode));
                    setIsPopPo(false);
                  }}
                >
                  {selectLangJson.next_episode}
                </QS.SaveButton>
              ) : (
                ""
              )}
              {isCourseComplete?.COURSE_COMPLETED &&
              !isCourseComplete?.NEXT_EPISODES ? (
                <QS.SaveButton
                  style={{ width: "50%" }}
                  themeColor={theme}
                  onClick={() => {
                    handleClose();
                    dispatch(setVideoPlay(false));
                    if (isCourseComplete?.Certificate_SCREEN) {
                      navigate(`${dynamicRoutes}/learner/courses/certificate`);
                    } else if (isCourseComplete?.VIEW_POLICY_DOCUMENTS) {
                      navigate(
                        `${dynamicRoutes}/learner/courses/policy-document`
                      );
                    }
                  }}
                >
                  {selectLangJson.complete_course}
                </QS.SaveButton>
              ) : (
                ""
              )}
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </>
  );
};

export default MediaPlayer;
