import React, {
  useState,
  Suspense,
  useEffect,
  lazy,
  useRef,
  startTransition,
} from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes, courseRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import * as SEC from "./styles/SectionStyledTwo";
import Navbar from "./components/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios, { updateAccessToken } from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
// const Course = lazy(() => import("./pages/private/Courses/Course"));
import Courses from "./pages/private/Courses/CourseContent/Courses";
import LayoutPage from "./Layout";
import VideoComponent from "./VideoComponent";
import {
  setAccessToken,
  setDynamicRoutes,
  setErrorPop,
  setIsActionDisabled,
  setLanguages,
  setOnBoarding,
  setSelectLang,
  setSelectLangJson,
  setShowVideoo,
} from "./store/action";
import CryptoJS from "crypto-js";
import { encryptData } from "./utils/Helpers";
import soundIcon from "./assets/images/soundIcon.png";
import democourse1 from "./assets/images/democourse1.png";
import demoImage1 from "./assets/images/demoImage1.png";
import demoImage2 from "./assets/images/demoImage2.png";
import demoImage3 from "./assets/images/demoImage3.png";
import demoImage4 from "./assets/images/demoImage4.png";
import demoImage5 from "./assets/images/demoImage5.png";
import demoImage6 from "./assets/images/demoImage6.png";
import Modal from "./components/Modal/Modal";
import demoImage7 from "./assets/images/demoImage7.png";
import { colorProvider } from "./utils/StylePropertiy";
import * as AS from "./styles/ArrivalStyled";
import { jwtDecode } from "jwt-decode";
import { Rating } from "react-simple-star-rating";
import warn from "./assets/images/warns.png";
import ModalComplete from "./components/Modal/ModalComplete";
import * as QS from "./styles/QuizStyled";
const secretKeyMain = "rmLearnerSecretKey";

const PublicRoute = () => {
  const videoRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const [bannerData, setBannerData] = useState([]);
  const [bannerConfigData, setBannerConfigData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
      setBannerConfigData(data?.config);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainerTwo>
                    <CS.BackContainer>
                      <video
                        ref={videoRef}
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                        handlePauseVideo={pauseVideo}
                      />
                      {/* {window.location.pathname === "/" ? ( */}
                      <LE.BannerImageBox>
                        <LE.BannerHead>
                          <LE.BannerTitle
                            style={{
                              width: bannerConfigData?.googleReviews
                                ? "75%"
                                : "100%",
                            }}
                          >
                            {bannerData?.showTextStatus
                              ? `${bannerData?.showText}`
                              : ""}
                          </LE.BannerTitle>
                          {bannerConfigData?.googleReviews ? (
                            <LE.ReviewBox>
                              <LE.ReviewBoxOne>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {/* Render 5 stars with halves */}
                                  {[1, 2, 3, 4, 5].map((starIndex) => (
                                    <div
                                      key={starIndex}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      {/* Left Half */}
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "50%",
                                          height: "100%",
                                          left: 0,
                                          zIndex: 1,
                                        }}
                                      ></div>

                                      {/* Right Half */}
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "50%",
                                          height: "100%",
                                          right: 0,
                                          zIndex: 1,
                                        }}
                                      ></div>

                                      {/* Full Star Icon */}
                                      <i
                                        className={`fa fa-star${
                                          bannerConfigData?.googleReviewsRating >=
                                          starIndex
                                            ? ""
                                            : bannerConfigData?.googleReviewsRating >=
                                              starIndex - 0.5
                                            ? "-half-full"
                                            : "-o"
                                        } fa-x`}
                                        style={{
                                          color:
                                            bannerConfigData?.googleReviewsRating >=
                                            starIndex - 0.5
                                              ? "#f5c518"
                                              : "#ccc",
                                        }}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                                <p>{bannerConfigData?.googleReviewsRating}</p>
                              </LE.ReviewBoxOne>
                              <LE.ReviewBoxTwo>
                                <p>
                                  from {bannerConfigData?.googleReviewsNumber}+
                                  reviews
                                </p>
                              </LE.ReviewBoxTwo>
                            </LE.ReviewBox>
                          ) : (
                            ""
                          )}
                        </LE.BannerHead>
                        {bannerData?.showBannerImageStatus ? (
                          <LE.BannerImage
                            href={bannerData?.bannerLink}
                            target="_blank"
                          >
                            <img src={bannerData?.bannerImage} alt="" />
                          </LE.BannerImage>
                        ) : (
                          ""
                        )}
                      </LE.BannerImageBox>
                      {/* ) : (
                        ""
                      )} */}
                    </CS.BackContainer>
                  </CS.InnerContainerTwo>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/*" element={<Navigate to="/learner" />} />
    </Routes>
  );
};
const PrivateRoute = ({ dynamicRoute }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state?.selectLang);
  const languages = useSelector((state) => state?.languages);
  const errorPop = useSelector((state) => state?.errorPop);
  const errorPopMsg = useSelector((state) => state?.errorPopMsg);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [localState, setLocalState] = useState({});
  useEffect(() => {
    if (selectLang) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      dispatch(
        setSelectLangJson(JSON.parse(filterLangJson?.localization_json))
      );
      const modifiedLangJson = {
        ...JSON.parse(filterLangJson?.localization_json),
      };
      dispatch(setSelectLangJson(modifiedLangJson));
      console.log(JSON.parse(filterLangJson?.localization_json), "Lang Json");
    }
  }, [selectLang]);
  const handleClose = () => {
    dispatch(setErrorPop(false));
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutPage dynamicRoute={dynamicRoute} />}>
          {privateRoutes.map((val, ind) => (
            <Route
              key={ind}
              path={`${dynamicRoute}${val.path}`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <val.component
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    courseId={courseId}
                    localState={localState}
                    dynamicRoute={dynamicRoute}
                  />
                </Suspense>
              }
            />
          ))}
        </Route>
        <Route path="/" element={<Courses dynamicRoute={dynamicRoute} />}>
          {courseRoutes.map((val, ind) => (
            <Route
              key={ind}
              path={`${dynamicRoute}${val.path}`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <val.component
                    navigate={navigate}
                    dispatch={dispatch}
                    location={location}
                    courseId={courseId}
                    theme={theme}
                    localState={localState}
                    dynamicRoute={dynamicRoute}
                  />
                </Suspense>
              }
            />
          ))}
        </Route>
        <Route
          path="/*"
          element={<Navigate to={`${dynamicRoute || ""}/learner/dashboard`} />}
        />
      </Routes>
      <ModalComplete
        isOpen={errorPop}
        maxWidth="400px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson?.warning}
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>
              {errorPopMsg === "expired_course"
                ? selectLangJson?.course_expired
                : ""}
            </SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "100%" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: "100%", padding: "1rem", fontSize: "1rem" }}
                themeColor={theme}
                onClick={() => {
                  // setIsPopPo(false);
                  dispatch(setErrorPop(false));
                }}
              >
                {selectLangJson?.cancel}
              </QS.CancelButton>
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </>
  );
};

const App = () => {
  // const state = useSelector((state) => state?.userData);
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.access_token);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  let location = useLocation();
  let navigate = useNavigate();
  const showVideo = useSelector((state) => state?.showVideo);
  const onBoarding = useSelector((state) => state?.onBoarding);

  console.log(showVideo, "showVideo vshowVideo");
  console.log(onBoarding, "onBoarding onBoarding");
  const [backVideo, setBackVideo] = useState(showVideo);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  // const [isOpen, setIsOpen] = useState(onBoarding);
  const [isOpen, setIsOpen] = useState(onBoarding);
  const [dynamicRoute, setDynamicRoute] = useState("");
  console.log(isOpen, "isOpen");
  console.log(onBoarding, "onBoarding");
  const [onBoardingScreens, setOnBoardingScreens] = useState({
    firstScreen: true,
    secondScreen: false,
    thirdScreen: false,
    fourthScreen: false,
    fifthScreen: false,
    sixthScreen: false,
  });
  let firstPlay =
    "https://sachin-testing.s3.ap-south-1.amazonaws.com/new_episodes/1734700078287_997156547/1734700078243_843175199.mp4";

  useEffect(() => {
    if (currentTime > 2) {
      if (currentTime === duration) {
        setBackVideo(false);
        dispatch(setShowVideoo(false));
      }
    }
  }, [currentTime, duration]);

  console.log("tick updated 4:05 PM");
  const [accessTokens, setAccessTokens] = useState(
    new URLSearchParams(location.search).get("accessToken")
  );
  console.log(location.search, "location.search");

  const [timeSt, setTimeSt] = useState(
    new URLSearchParams(location.search).get("timestamp")
  );
  const [userId, setUserId] = useState(
    new URLSearchParams(location.search).get("userId")
  );
  const [otpCode, setOtpCode] = useState(
    new URLSearchParams(location.search).get("code")
  );
  const [screenJump, setScreenJump] = useState(
    new URLSearchParams(location.search).get("type")
  );
  const [data, setData] = useState(
    new URLSearchParams(location.search).get("data")
  );
  const [userType, setuserType] = useState(
    new URLSearchParams(location.search).get("userType")
  );
  const getUserList = async (e) => {
    try {
      const decodedUserType = userType?.replace(/['"]+/g, "");
      console.log(
        decodedUserType,
        "decodedUserType decodedUserTypedecodedUserType"
      );
      let url =
        decodedUserType === "client_admin"
          ? `${RMlogin.getClientLearner}${e}?userType=client_admin`
          : `${RMlogin.getClientLearner}${e}`;

      console.log(url, "url urlurlurlurlurl");
      const response = await axios.get(url);
      const { data } = response;
      console.log({ data }, "user list data");

      if (data?.data && data?.data?.HOME_SCREEN) {
        console.log(data, "inner one");
        const encryptedAccessToken = CryptoJS.AES.encrypt(
          data?.token?.accessToken,
          secretKeyMain
        ).toString();
        localStorage.setItem("rainLearnerToken", encryptedAccessToken);
        localStorage.setItem("languagesObj", encryptData(data?.data?.languges));
        const encryptedActionDisbaled = CryptoJS.AES.encrypt(
          data?.data?.ActionDisabled,
          secretKeyMain
        ).toString();
        localStorage.setItem("isActionDisabled", encryptedActionDisbaled);
        updateAccessToken(data?.token?.accessToken);
        dispatch(setAccessToken(data?.token?.accessToken));
        dispatch(setLanguages(data?.data?.languges));
        dispatch(setSelectLang(data?.data?.languges[0]?._id));
        dispatch(setIsActionDisabled(data?.data?.ActionDisabled));
        dispatch(setShowVideoo(true));
        dispatch(setOnBoarding(true));
        const encryptedSelectLang = CryptoJS.AES.encrypt(
          data?.data?.languges[0]?._id,
          secretKeyMain
        ).toString();
        window.localStorage.setItem("rainLearnerLang", encryptedSelectLang);
        // Navigate to dashboard
        navigate("/learner/dashboard");
      } else {
        console.log("HOME_SCREEN not found in data");
      }
    } catch (error) {
      console.error(
        "Error fetching user list: ",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  useEffect(() => {
    const decodedScreenJump = screenJump?.replace(/['"]+/g, "");
    if (userId && decodedScreenJump === "impersonate") {
      getUserList(userId);
    }
  }, [userId, screenJump]);

  console.log(screenJump, "screenJump screenJump");

  // useEffect(() => {
  //   if (window.location.pathname?.includes("redirect")) {
  //     jumpFun();
  //   }
  // }, [accessTokens, screenJump, userId]);

  const jumpFun = () => {
    localStorage.clear();
    dispatch(setAccessToken(""));
    console.log("Step 1");
    console.log("Step 1", window.location.pathname);

    const decodedScreenJump = screenJump?.replace(/['"]+/g, ""); // Remove extra quotes
    console.log("Decoded screenJump:", decodedScreenJump);

    if (decodedScreenJump === "resetPassword") {
      updateAccessToken(accessTokens);
      // localStorage.setItem("rainLearnerToken", accessTokens);
      console.log("Step 2");
      navigate("/learner/reset-password", {
        state: {
          userId: userId,
        },
      });
    } else if (decodedScreenJump === "impersonate") {
      console.log("Step 3");
      // Handle impersonation logic
    }

    console.log("Step 4");
  };

  const handleScreenClick = (screen) => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
      [screen]: true,
    });
  };

  const resetScreens = () => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
    });
    setIsOpen(false);
    dispatch(setOnBoarding(false));
  };

  useEffect(() => {
    if (onBoarding) {
      setIsOpen(true);
      setOnBoardingScreens({
        firstScreen: true,
        secondScreen: false,
        thirdScreen: false,
        fourthScreen: false,
        fifthScreen: false,
        sixthScreen: false,
      });
    }
  }, [onBoarding]);

  // const getTokenFun = () => {
  //   const encryptedAccessToken = localStorage.getItem("rainLearnerToken");
  //   if (encryptedAccessToken) {
  //     try {
  //       return CryptoJS.AES.decrypt(
  //         encryptedAccessToken,
  //         secretKeyMain
  //       ).toString(CryptoJS.enc.Utf8);
  //     } catch (error) {
  //       console.error("Token decryption failed", error);
  //     }
  //   }
  //   return null;
  // };

  // useEffect(() => {
  //   const token = getTokenFun();
  //   if (token) {
  //     try {
  //       const decoded = jwtDecode(token);
  //       setDynamicRoute(decoded?.micrositeURL || "");
  //       localStorage.setItem("rainRoute", decoded?.micrositeURL);
  //       dispatch(setDynamicRoutes(decoded?.micrositeURL));
  //     } catch (error) {
  //       console.error("JWT decoding failed", error);
  //     }
  //   }
  // }, []);

  console.log(typeof showVideo, "Bobo");
  console.log(showVideo, "showVideo");
  console.log(backVideo, "backVideo");

  useEffect(() => {
    if (showVideo) {
      setBackVideo(true);
    }
  }, [showVideo]);
  return (
    <>
      <CS.ParentBox>
        {window.location.pathname?.includes("redirect") ? (
          jumpFun()
        ) : access_token ? (
          <CS.OverLapCon>
            {backVideo ? (
              <VideoComponent
                handleDuration={setDuration}
                handleCurrentTime={setCurrentTime}
                firstPlay={firstPlay}
              />
            ) : (
              ""
            )}

            <PrivateRoute dynamicRoute={dynamicRoutes} />
          </CS.OverLapCon>
        ) : (
          <PublicRoute />
        )}
      </CS.ParentBox>
      <Modal
        maxWidth={"700px"}
        isOpen={isOpen}
        backColor={colorProvider.darkBlue}
        component={
          <AS.ArrContainer>
            {onBoardingScreens?.firstScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst>

                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.welcome_to_workflix}
                      </div>
                      <div className="desc">{selectLangJson?.welcome_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("secondScreen")}
                      >
                        {selectLangJson?.get_started}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.secondScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage2} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.course_onboad_head}
                      </div>
                      <div className="desc">{selectLangJson?.course_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("thirdScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.thirdScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage3} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.support_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.support_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fourthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fourthScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage4} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.language_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.language_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fifthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fifthScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage5} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">{selectLangJson?.dark_head}</div>
                      <div className="desc">{selectLangJson?.dark_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("sixthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.sixthScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage7} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.profile_head}
                      </div>
                      <div className="desc">{selectLangJson?.profile_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div className="orangebox" onClick={resetScreens}>
                        {selectLangJson?.close}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}
          </AS.ArrContainer>
        }
      />
    </>
    // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
  );
};

export default App;
