import React, { useState, useEffect, useRef } from "react";
import * as HS from "../../styles/HeaderStyled";
import logoImage from "../../assets/images/rainLogo.png";
import downIcon from "../../assets/images/downIcon.png";
import searchIcon from "../../assets/images/searchIcon.png";
import langIcon from "../../assets/images/langIcon.png";
import bellIcon from "../../assets/images/bellIcon.png";
import sunIcon from "../../assets/images/sunIcon.png";
import hills from "../../assets/images/hills.png";
import Avatar from "../../assets/images/Avatar.png";
import lightIcon from "../../assets/images/light.png";
import darkIcon from "../../assets/images/dark.png";
import {
  setAccessToken,
  setErrorPop,
  setErrorPopMsg,
  setIsDark,
  setLanaguageId,
  setOnBoarding,
  setProfileData,
  setSelectLang,
  setSelectLangJson,
  setShowVideoo,
  setSidebarIsOpens,
  setTimeStamp,
  setUserData,
  setVideoPlay,
} from "../../store/action";
import darkLogo from "../../assets/images/rainLogoDark.png";
import darkSearch from "../../assets/images/darkSearch.png";
import darkLang from "../../assets/images/darkLang.png";
import darkBell from "../../assets/images/darkBell.png";
import darkDonwArrow from "../../assets/images/darkDonwArrow.png";
import drawer from "../../assets/images/drawer.png";
import darkDrawer from "../../assets/images/darkDrawer.png";
import AvatarIcon from "../../assets/images/Avatar.png";
import mainLogo from "../../assets/images/rainLogo.png";
import crossBig from "../../assets/images/crossBig.png";
import selectIcon from "../../assets/images/selectIcon.png";
import accountIcon from "../../assets/images/accountIcon.png";
import logoutIconGrey from "../../assets/images/logoutIconGrey.png";
import infoIcon from "../../assets/images/infoIcon.png";
import lightConfigure from "../../assets/images/lightConfigure.png";
import warns from "../../assets/images/warns.png";
import rainLogoLight from "../../assets/images/rainLightSvg.svg";
import rainLogoDarkTwo from "../../assets/images/rainDarkSvg.svg";
import tanaya from "../../assets/images/tanaya.jpg";
import * as LS from "../../styles/LearnerStyled";
import Switch from "../../components/Switch/Switch";
import SelectQues from "../../components/SelectQues";
import { useSelector, useDispatch } from "react-redux";
import englishLang from "../../utils/Languages/english.json";
import hindiLang from "../../utils/Languages/hindi.json";
import tamilLang from "../../utils/Languages/tamil.json";
import kannadaLang from "../../utils/Languages/kannada.json";
import { jwtDecode } from "jwt-decode";
import { get, includes } from "lodash";
import {
  Episode,
  GetNotification,
  NavbarAP,
  ProfileInfo,
} from "../../utils/api";
import axios from "../../utils/axios";
import profileDemo from "../../assets/images/profileDemo.png";
import moment from "moment";
import ModalComplete from "../../components/Modal/ModalComplete";
import * as QS from "../../styles/QuizStyled";
import * as SEC from "../../styles/SectionStyledTwo";
import successModal from "../../assets/images/successModal.png";
import squareImage from "../../assets/images/squareImage.png";
import warnIcon from "../../assets/images/warn.png";
import CryptoJS from "crypto-js";
import { PopUp } from "../../components/PopUp/PopUp";
import { ModalSuccess } from "../../components/Modal/ModalSuccess";
import { convertSecondsToTimeString } from "../../utils/Helpers";
const secretKeyMain = "rmLearnerSecretKey";

const optionsLang = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "हिंदी",
    value: "hi",
  },
  {
    label: "தமிழ்",
    value: "tm",
  },
  {
    label: "ಕನ್ನಡ",
    value: "kn",
  },
];
const Navbar = ({ theme, navigate, stateData }) => {
  const access_token = useSelector((state) => state?.access_token);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const languages = useSelector((state) => state?.languages);
  const selectLang = useSelector((state) => state?.selectLang);
  const profileUpdate = useSelector((state) => state?.profileUpdate);
  const profileData = useSelector((state) => state?.profileData);
  const timeStamp = useSelector((state) => state?.timeStamp);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  const [loginValues, setLoginValues] = useState([]);
  const [langOptions, setLangOptions] = useState(
    languages?.map((val) => {
      return {
        label: val.title,
        value: val._id,
      };
    })
  );
  const dispatch = useDispatch();
  const decoded = jwtDecode(access_token);
  const [languageData, setLanguageData] = useState(
    get(stateData, "data.client_info.language", [])?.map((val) => {
      return {
        label: val.title,
        value: val._id,
        isDefault: val.isDefault,
      };
    })
  );
  const [isOpen, setIsOpen] = useState(true);
  const [isRedBox, setIsRedBox] = useState(false);
  useEffect(() => {
    // Initialize theme from local storage if available
    const savedTheme = localStorage.getItem("rainTheme");
    if (savedTheme !== null) {
      dispatch(setIsDark(savedTheme === "true")); // Convert the string to a boolean
    }
  }, [dispatch]);
  const profileRef = useRef(null); // Create a ref for the profile menu
  const notificationRef = useRef(null); // Create a ref for the profile menu
  const supportRef = useRef(null); // Create a ref for the profile menu
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isPopPo, setIsPopPo] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [opens, setOpens] = useState(false);
  const courseId = useSelector((state) => state?.courseId);
  const episodeId = useSelector((state) => state?.episodeId);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const handleThemeChange = () => {
    const newTheme = !theme;
    localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
    dispatch(setIsDark(newTheme));
  };
  const [defaultLang, setDefaultLang] = useState(selectLang);
  useEffect(() => {
    if (languageData?.length > 0) {
      let selectedLang = languageData?.find((val) => val.isDefault === true);
      setDefaultLang(selectedLang);
    }
  }, [languageData]);
  const [innerwidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSidebarChange = () => {
    dispatch(setSidebarIsOpens(true));
  };
  const handleConfirm = () => {
    setDeleteSuccess(true);
    setIsOpen(false);
    setOpens(false);
    setTimeout(() => {
      setDeleteSuccess(false);
      localStorage.removeItem("rainLearnerData");
      localStorage.removeItem("rainLearnerToken");
      localStorage.removeItem("rainLearnerLang");
      localStorage.removeItem("languagesObj");
      localStorage.removeItem("isActionDisabled");
      localStorage.removeItem("rainRMUserData");
      localStorage.removeItem("rainLearnerCourseId");
      localStorage.removeItem("rainClientUserData");
      localStorage.removeItem("rainRoute");
      dispatch(setUserData(""));
      dispatch(setShowVideoo(false));
      dispatch(setUserData(""));
      dispatch(setOnBoarding(false));
      dispatch(setUserData(""));
      dispatch(setAccessToken(""));
      window.location.ref = "/";
    }, 2000);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
      if (supportRef.current && !supportRef.current.contains(event.target)) {
        setIsSupport(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef, notificationRef, supportRef]);

  const getProfile = (e) => {
    if (e === "tanaya@rainmaker.co.in") {
      return tanaya;
    } else {
      return Avatar;
    }
  };

  const handleChangeLanguage = (selectedOption) => {
    if (window.location.pathname === "/learner/courses-content") {
      setIsPopPo(true);
    } else {
      handleConfirmChangeLanguage();
    }
    setSelectedLanguage(selectedOption.value);
  };

  const handleConfirmChangeLanguage = () => {
    // Execute the language change logic
    dispatch(setSelectLang(selectedLanguage)); // Assuming this action updates the language
    setDefaultLang(selectedLanguage);
    const encryptedAccessToken = CryptoJS.AES.encrypt(
      selectedLanguage,
      secretKeyMain
    ).toString();
    window.localStorage.setItem("rainLearnerLang", encryptedAccessToken);
    // Close the modal
    setIsPopPo(false);
  };
  const setLangFun = async (e) => {
    try {
      let url = `${NavbarAP.setLang}${e}`;
      const { data } = await axios.get(url);
      console.log(data, "data data");
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileDetails = async () => {
    try {
      let url = ProfileInfo.getProfileDetails;
      const { data } = await axios.get(url);
      // if (data.data) {
      //   setLoginValues({
      //     userName: get(data.data, "userName", ""),
      //     email: get(data.data, "email", ""),
      //     profileImage: get(data.data, "profileImage", ""),
      //   });
      // } else {
      //   setLoginValues({
      //     userName: "",
      //     email: "",
      //     profileImage: "",
      //   });
      // }
      if (data && data.data) {
        const { userName, email, profileImage, switchToUser, logo } = data.data;

        console.log(logo, "logo  logo");
        const userData = {
          userName: userName || "",
          email: email || "",
          profileImage: profileImage || "",
          switchToUser: switchToUser || false,
          logo: logo?.logoImage,
        };
        console.log(userData, "userData userData userData");
        setLoginValues(userData);
        dispatch(setProfileData(userData));
        const encryptedRainUserData = CryptoJS.AES.encrypt(
          JSON.stringify(userData),
          secretKeyMain
        ).toString();
        localStorage.setItem("rainRMUserData", encryptedRainUserData);
      } else {
        setLoginValues({
          userName: "",
          email: "",
          profileImage: "",
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const removeImpersonate = async () => {
    try {
      let url = GetNotification.removeImpersonate;
      const { data } = await axios.get(url);
      console.log(data?.data);

      if (data?.data) {
        handleConfirm();
        if (decoded?.clientAdmin) {
          if (data?.data.redirect) window.location.href = data?.data.redirect;
        } else {
          window.close();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleConfirm();
      }
    }
  };

  useEffect(() => {
    if (
      !profileData ||
      Object.keys(profileData)?.length === 0 ||
      !profileUpdate
    ) {
      getProfileDetails();
    }
  }, [profileData]);

  const getNotification = async () => {
    let url = GetNotification.getNotification;
    try {
      const formValue = {
        limit: 6,
        page: 1,
      };
      const { data } = await axios.post(url, formValue);
      if (data?.data) {
        setNotificationData(data?.data?.docs);
        const hasReadMark = data?.data?.docs?.some((val) => val.readMark);
        setIsRedBox(hasReadMark);
        setIsNotificationOpen(!isNotificationOpen);
      } else {
        setNotificationData([]);

        setIsNotificationOpen(!isNotificationOpen);
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    } finally {
    }
  };

  const handleClose = () => {
    setIsPopPo(false);
  };

  const handleCloses = () => {
    setOpens(false);
    setDeleteSuccess(false);
  };

  const courseRouteArr = [
    `${dynamicRoutes}/learner/course`,
    `${dynamicRoutes}/learner/courses-content`,
    `${dynamicRoutes}/learner/quiz-result`,
    `${dynamicRoutes}/learner/quiz`,
    `${dynamicRoutes}/learner/courses/quiz-result`,
    `${dynamicRoutes}/learner/courses/feedback`,
    `${dynamicRoutes}/learner/courses/policy-document`,
    `${dynamicRoutes}/learner/courses/certificate`,
    `${dynamicRoutes}/learner/courses/committee`,
  ];

  console.log(timeStamp, "timeStamp timeStamp");

  const nameFuntion = async (e) => {
    let url = Episode.timeSubmit;
    const formValues = {
      courseId: courseId,
      episodeId: episodeId,
      languageId: episodeSelectLang,
      last_time_stamp: convertSecondsToTimeString(e),
    };

    try {
      const { data } = await axios.post(url, formValues);
    } catch (error) {
      dispatch(setVideoPlay(false));
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };
  return (
    <>
      <HS.HeaderBox themeColor={theme}>
        {innerwidth > 768 ? (
          <>
            <HS.NavBar>
              <HS.FirstContainer>
                {theme ? (
                  <HS.MainLogoTwo
                    onClick={() => {
                      navigate(`${dynamicRoutes}/learner/dashboard`);
                      nameFuntion(timeStamp);
                    }}
                  >
                    <img src={rainLogoDarkTwo} alt="" />
                  </HS.MainLogoTwo>
                ) : (
                  <HS.MainLogoTwo
                    onClick={() => {
                      navigate(`${dynamicRoutes}/learner/dashboard`);
                      nameFuntion(timeStamp);
                    }}
                  >
                    <img src={rainLogoLight} alt="" />
                  </HS.MainLogoTwo>
                )}
                <HS.Options
                  themeColor={theme}
                  to="/learner"
                  onClick={() => {
                    dispatch(setVideoPlay(false));
                    nameFuntion(timeStamp);
                  }}
                  isActive={window.location.pathname?.includes(
                    "/learner/dashboard"
                  )}
                >
                  {selectLangJson?.dashboard}
                </HS.Options>
                <HS.Options
                  themeColor={theme}
                  to={`${dynamicRoutes}/learner/course`}
                  onClick={() => {
                    dispatch(setVideoPlay(false));
                    nameFuntion(timeStamp);
                  }}
                  isActive={courseRouteArr?.includes(window.location.pathname)}
                >
                  {selectLangJson?.courses}
                </HS.Options>
                <HS.OptionsSupport
                  onClick={() => {
                    setIsSupport(!isSupport);
                    dispatch(setVideoPlay(false));
                    nameFuntion(timeStamp);
                  }}
                  themeColor={theme}
                >
                  <HS.ComSupport themeColor={theme}>
                    <span>{selectLangJson.support}</span>
                    <HS.DownLogo
                      src={theme ? darkDonwArrow : downIcon}
                      alt=""
                    />
                  </HS.ComSupport>
                  {isSupport ? (
                    <HS.SupportMenuCon ref={supportRef} themeColor={theme}>
                      <p>{selectLangJson?.raise_a_ticket}</p>
                      <p>{selectLangJson?.chat_with_us}</p>
                      <p
                        onClick={() => {
                          dispatch(setOnBoarding(true));
                          dispatch(setVideoPlay(false));
                          nameFuntion(timeStamp);
                        }}
                      >
                        {selectLangJson?.onBoarding_guide}
                      </p>
                    </HS.SupportMenuCon>
                  ) : (
                    ""
                  )}
                </HS.OptionsSupport>
              </HS.FirstContainer>
              <HS.SecondContainer>
                {/* <HS.ComIconContainers themeColor={theme}>
                <img src={theme ? darkSearch : searchIcon} alt="" />
              </HS.ComIconContainers> */}
                <HS.ComIconContainersDown themeColor={theme}>
                  <img src={theme ? darkLang : langIcon} alt="" />
                  <SelectQues
                    options={langOptions}
                    star={false}
                    value={langOptions?.find(
                      (val) => val.value === defaultLang
                    )}
                    placeholder="Select"
                    onChange={(e) => {
                      dispatch(setSelectLang(e.value));
                      setDefaultLang(e.value);
                      setLangFun(e.value);
                      const encryptedAccessToken = CryptoJS.AES.encrypt(
                        e.value,
                        secretKeyMain
                      ).toString();
                      window.localStorage.setItem(
                        "rainLearnerLang",
                        encryptedAccessToken
                      );
                    }}
                  />
                </HS.ComIconContainersDown>
                <HS.ComIconContainers
                  style={{ position: "relative" }}
                  themeColor={theme}
                >
                  <img
                    onClick={() => {
                      getNotification();
                    }}
                    src={theme ? darkBell : bellIcon}
                    alt=""
                  />
                  {/* {isRedBox ? <HS.RedContainer /> : ""} */}
                </HS.ComIconContainers>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleThemeChange}
                  style={{ borderRight: "0" }}
                >
                  <img src={theme ? darkIcon : lightIcon} alt="" />
                </HS.ComIconContainers>
                <HS.ProfileImage
                  onClick={() => {
                    setIsProfileOpen(!isProfileOpen);
                  }}
                  style={{ marginRight: "15px" }}
                >
                  <img src={profileData?.profileImage || profileDemo} alt="" />
                </HS.ProfileImage>
                <HS.ProfileLogoImage
                  themeColor={theme}
                  style={{ marginRight: "15px" }}
                >
                  <img src={profileData?.logo || squareImage} alt="" />
                </HS.ProfileLogoImage>
                {isProfileOpen ? (
                  <HS.AccountBox themeColor={theme} ref={profileRef}>
                    <HS.ProfileRow themeColor={theme}>
                      <HS.ProfileBox>
                        <img
                          src={profileData?.profileImage || profileDemo}
                          alt=""
                        />
                      </HS.ProfileBox>
                      <HS.NameBox themeColor={theme}>
                        <HS.NameTitle themeColor={theme}>
                          {profileData?.userName}
                        </HS.NameTitle>
                        <HS.NameEmail themeColor={theme}>
                          {profileData?.email}
                        </HS.NameEmail>
                      </HS.NameBox>
                    </HS.ProfileRow>
                    <HS.ComMenuBoxNoBorder
                      themeColor={theme}
                      to={`${dynamicRoutes}/learner/profile-details`}
                      onClick={() => {
                        setIsProfileOpen(false);
                        dispatch(setVideoPlay(false));
                        nameFuntion(timeStamp);
                      }}
                    >
                      <img src={accountIcon} alt="" />
                      {selectLangJson?.account_settings}
                    </HS.ComMenuBoxNoBorder>
                    {/* <HS.ComMenuBox
                    themeColor={theme}
                    to="/learner/profile-details"
                    onClick={() => {
                      setIsProfileOpen(false);
                      dispatch(setVideoPlay(false));
                    }}
                  >
                    <img src={lightConfigure} alt="" />
                    {selectLangJson.settings}
                  </HS.ComMenuBox> */}
                    {profileData?.switchToUser && (
                      <HS.ComMenuBoxCon
                        themeColor={theme}
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={accountIcon} alt="" />
                          {selectLangJson.switch_to_user}
                        </div>
                        <LS.DefaultToggle>
                          <Switch
                            rounded={true}
                            isToggled={isOpen}
                            bgColorChecked="#F6591C"
                            roundColorChecked="#ffffff"
                            border={
                              theme
                                ? "1px solid transparent"
                                : "1px solid #D7DAE0"
                            }
                            themeColor={theme}
                            wiDth={"40px"}
                            bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                            roundColorUnChecked="#ffffff"
                            onToggle={() => {
                              setIsOpen(!isOpen);
                              removeImpersonate();
                            }}
                          />
                        </LS.DefaultToggle>
                      </HS.ComMenuBoxCon>
                    )}

                    <HS.ComMenuBox
                      themeColor={theme}
                      onClick={() => {
                        setOpens(true);
                      }}
                      style={{ border: "none" }}
                    >
                      <img src={logoutIconGrey} alt="" />
                      {selectLangJson.log_out}
                    </HS.ComMenuBox>
                  </HS.AccountBox>
                ) : (
                  ""
                )}
                {isNotificationOpen ? (
                  <HS.NotificationBox themeColor={theme} ref={notificationRef}>
                    <HS.NotiTitleRow themeColor={theme}>
                      <HS.NotiTitle themeColor={theme}>
                        {selectLangJson.notification}
                      </HS.NotiTitle>
                      <HS.SeeAllText
                        themeColor={theme}
                        to={`${dynamicRoutes}/learner/notification`}
                        onClick={() => {
                          setIsNotificationOpen(false);
                          dispatch(setVideoPlay(false));
                          nameFuntion(timeStamp);
                        }}
                      >
                        {selectLangJson.see_all}
                      </HS.SeeAllText>
                    </HS.NotiTitleRow>
                    <HS.NotiContentBox themeColor={theme}>
                      {notificationData?.length > 0
                        ? notificationData?.slice(0, 5)?.map((val, ind) => {
                            // if (val.readMark) {
                            //   setIsRedBox(true);
                            // }
                            return (
                              <HS.NotificationCard key={ind} themeColor={theme}>
                                <HS.NotiLogo>
                                  <img src={infoIcon} alt="" />
                                </HS.NotiLogo>
                                <HS.NotiContent>
                                  <HS.NotiMessage themeColor={theme}>
                                    {val.body}
                                  </HS.NotiMessage>
                                  <HS.TimeViewRow>
                                    <HS.TimeBox themeColor={theme}>
                                      {moment(val.createdAt).format("h:mm:ss")}
                                      &nbsp;&nbsp;
                                      {moment(val.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </HS.TimeBox>
                                    {val.viewStatus ? (
                                      <HS.ViewBox themeColor={theme}>
                                        {selectLangJson?.view}
                                      </HS.ViewBox>
                                    ) : (
                                      ""
                                    )}
                                  </HS.TimeViewRow>
                                </HS.NotiContent>
                              </HS.NotificationCard>
                            );
                          })
                        : ""}

                      {/* <HS.NotificationCard themeColor={theme}>
                      <HS.NotiLogo>
                        <img src={warns} alt="" />
                      </HS.NotiLogo>
                      <HS.NotiContent>
                        <HS.NotiMessage themeColor={theme}>
                          You have a new request of additional license from
                          Deepika for the WorkSafe Delta.
                        </HS.NotiMessage>
                        <HS.TimeViewRow themeColor={theme}>
                          <HS.TimeBox themeColor={theme}>
                            18-01-2023 10:56:42
                          </HS.TimeBox>
                        </HS.TimeViewRow>
                      </HS.NotiContent>
                    </HS.NotificationCard>
                    <HS.NotificationCard themeColor={theme}>
                      <HS.NotiLogo>
                        <img src={infoIcon} alt="" />
                      </HS.NotiLogo>
                      <HS.NotiContent>
                        <HS.NotiMessage themeColor={theme}>
                          You have a new request of additional license from
                          Deepika for the WorkSafe Delta.
                        </HS.NotiMessage>
                        <HS.TimeViewRow>
                          <HS.TimeBox themeColor={theme}>
                            18-01-2023 10:56:42
                          </HS.TimeBox>
                          <HS.ViewBox themeColor={theme}>View</HS.ViewBox>
                        </HS.TimeViewRow>
                      </HS.NotiContent>
                    </HS.NotificationCard>
                    <HS.NotificationCard themeColor={theme}>
                      <HS.NotiLogo>
                        <img src={infoIcon} alt="" />
                      </HS.NotiLogo>
                      <HS.NotiContent>
                        <HS.NotiMessage themeColor={theme}>
                          You have a new request of additional license from
                          Deepika for the WorkSafe Delta.
                        </HS.NotiMessage>
                        <HS.TimeViewRow themeColor={theme}>
                          <HS.TimeBox themeColor={theme}>
                            18-01-2023 10:56:42
                          </HS.TimeBox>
                          <HS.ViewBox themeColor={theme}>View</HS.ViewBox>
                        </HS.TimeViewRow>
                      </HS.NotiContent>
                    </HS.NotificationCard>
                    <HS.NotificationCard themeColor={theme}>
                      <HS.NotiLogo>
                        <img src={warns} alt="" />
                      </HS.NotiLogo>
                      <HS.NotiContent>
                        <HS.NotiMessage themeColor={theme}>
                          You have a new request of additional license from
                          Deepika for the WorkSafe Delta.
                        </HS.NotiMessage>
                        <HS.TimeViewRow>
                          {" "}
                          themeColor={theme}
                          <HS.TimeBox themeColor={theme}>
                            18-01-2023 10:56:42
                          </HS.TimeBox>
                        </HS.TimeViewRow>
                      </HS.NotiContent>
                    </HS.NotificationCard> */}
                    </HS.NotiContentBox>
                  </HS.NotificationBox>
                ) : (
                  ""
                )}
              </HS.SecondContainer>
            </HS.NavBar>
          </>
        ) : (
          ""
        )}

        {innerwidth <= 768 ? (
          <>
            <HS.NavBar>
              <HS.FirstContainer>
                <HS.MainLogo
                  onClick={() => navigate(`${dynamicRoutes}/learner/dashboard`)}
                >
                  <img src={theme ? rainLogoDarkTwo : rainLogoDarkTwo} alt="" />
                </HS.MainLogo>
              </HS.FirstContainer>
              <HS.SecondContainer style={{ width: "auto" }}>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleThemeChange}
                  style={{ borderRight: "0" }}
                >
                  <img src={theme ? darkIcon : lightIcon} alt="" />
                </HS.ComIconContainers>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleSidebarChange}
                  style={{ borderRight: "0" }}
                >
                  <img
                    src={theme ? darkDrawer : drawer}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  />
                </HS.ComIconContainers>
              </HS.SecondContainer>
            </HS.NavBar>
          </>
        ) : (
          ""
        )}
        <ModalComplete
          isOpen={isPopPo}
          maxWidth="400px"
          theme={theme}
          component={
            <SEC.InnerSectionSuccessSoEpCom
              themeColor={theme}
              style={{ padding: "1rem 0" }}
            >
              <SEC.SuccessIconBoxSo>
                <img src={theme ? successModal : successModal} alt="danger" />
              </SEC.SuccessIconBoxSo>
              <SEC.TitleSo themeColor={theme}>
                {selectLangJson?.warning}
              </SEC.TitleSo>
              <SEC.Subtitle themeColor={theme}>
                {selectLangJson?.if_you_change_the_video_language}
              </SEC.Subtitle>
              <QS.ButtonGroup style={{ width: "300px" }} themeColor={theme}>
                <QS.CancelButton
                  style={{ width: "50%" }}
                  themeColor={theme}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {selectLangJson?.cancel}
                </QS.CancelButton>
                <QS.SaveButton
                  style={{ width: "50%" }}
                  themeColor={theme}
                  onClick={handleConfirmChangeLanguage}
                >
                  {selectLangJson?.ok_continue}
                </QS.SaveButton>
              </QS.ButtonGroup>
            </SEC.InnerSectionSuccessSoEpCom>
          }
        />
      </HS.HeaderBox>
      <PopUp
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="600px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.TitleNormal
                style={{ textAlign: "center" }}
                themeColor={theme}
              >
                {selectLangJson.are_you_sure}!
              </SEC.TitleNormal>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                {selectLangJson.are_you_sure_exit}
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                  type="button"
                >
                  {selectLangJson.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  {selectLangJson.yes_logout}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
      <ModalSuccess
        isOpen={deleteSuccess}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxThree>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxThree>
          </>
        }
        content={
          <SEC.InnerSectionSuccessTwo>
            <SEC.TitleSuccess themeColor={theme}>
              {selectLangJson.great}!
            </SEC.TitleSuccess>
            {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or) 
              </SEC.TitleDesc> */}
            <SEC.Subtitle themeColor={theme}>
              {selectLangJson.success_log_out}
            </SEC.Subtitle>
            <SEC.GreenContinue
              style={{ width: "100%" }}
              onClick={() => {
                handleCloses();

                localStorage.removeItem("rainLearnerData");
                localStorage.removeItem("rainLearnerToken");
                localStorage.removeItem("rainLearnerLang");
                localStorage.removeItem("languagesObj");
                localStorage.removeItem("isActionDisabled");
                localStorage.removeItem("rainRMUserData");
                localStorage.removeItem("rainLearnerCourseId");
                localStorage.removeItem("rainClientUserData");
                dispatch(setUserData(""));
                dispatch(setAccessToken(""));
                window.location.ref = "/";
              }}
            >
              {selectLangJson.ok}
            </SEC.GreenContinue>
          </SEC.InnerSectionSuccessTwo>
        }
      />
    </>
  );
};

export default Navbar;
