import React, { useEffect, useState, useRef } from "react";
import * as CS from "../../../../styles/CourseViewStyled";
import * as CSS from "../../../../styles/CommonStyled";
import * as HS from "../../../../styles/HeaderStyled";
import * as QS from "../../../../styles/QuizStyled";
import langIcon from "../../../../assets/images/langIcon.png";
import darkLang from "../../../../assets/images/darkLang.png";
import darkDonwArrow from "../../../../assets/images/darkDonwArrow.png";
import downIcon from "../../../../assets/images/downIcon.png";
import medalIcon from "../../../../assets/images/medalIcon.png";
import cupIcon from "../../../../assets/images/cupIcon.png";
import lightTrophy from "../../../../assets/images/lightTrophy.png";
import googleIcon from "../../../../assets/images/googleIcon.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import darkTitleBottom from "../../../../assets/images/darkTitleBottom.png";
import lightTitleBottom from "../../../../assets/images/lightTitleBottom.png";
import darkTitleTop from "../../../../assets/images/darkTitleTop.png";
import lightTitleTop from "../../../../assets/images/lightTitleTop.png";
import episodeLogo from "../../../../assets/images/episodeLogo.png";
import greenCheck from "../../../../assets/images/greenCheck.png";
import greyClock from "../../../../assets/images/greyClock.png";
import rewardIcon from "../../../../assets/images/rewardIcon.png";
import tanaya from "../../../../assets/images/tanaya.jpg";
import MoreLessEpisode from "../../../../components/MoreLessEpisode";
import BannerVew from "../BannerVew";
import SidebarView from "../SidebarView";
import Modal from "../../../../components/Modal/Modal";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../../../../utils/StylePropertiy";
import { Course, Episode } from "../../../../utils/api";
import crossIcon from "../../../../assets/images/crossIcon.png";
import rewardLightsUnWatch from "../../../../assets/images/rewardLightsUnWatch.png";
import numberOne from "../../../../assets/images/numberOne.png";
import numberTwo from "../../../../assets/images/numberTwo.png";
import numberThree from "../../../../assets/images/numberThree.png";
import profileDemo from "../../../../assets/images/user_icon.png";
import modeltwo from "../../../../assets/images/modeltwo.png";
import modelthree from "../../../../assets/images/modelthree.png";
import Crown_1_ from "../../../../assets/images/Crown_1_.png";
import model1 from "../../../../assets/images/model1.jpeg";
import model2 from "../../../../assets/images/model2.png";
import model3 from "../../../../assets/images/model3.png";
import rewardLight from "../../../../assets/images/rewardLight.png";
import episodePlay from "../../../../assets/images/episodePlay.png";
import demoCard from "../../../../assets/images/demoCard.png";
import * as MS from "../../../../styles/ModelStyled";
import axios from "../../../../utils/axios";
import HeaderView from "../HeaderView";
import { get } from "lodash";
import { convertTimeFormat } from "../../../../utils/Helpers";
import {
  setBannerThumb,
  setButtonName,
  setCompleteProgressBar,
  setEpisodeCompleted,
  setEpisodeId,
  setTemplateId,
  setVideoPlay,
  setCourseMenu,
  setCourseMenuData,
  setErrorPop,
  setErrorPopMsg,
} from "../../../../store/action";
import CourseContent from "./CourseContent";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import Navbar from "../../../../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import englishLang from "../../../../utils/Languages/english.json";
import LearnerBadges from "../LearnerBadges";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

let descText =
  "The PoSH Awareness Module ensures that you are in compliance with the Law. Further, the module ensures that all Employees are sensitized and there is awareness with respect to their rights and obligations in accordance with the Law. The module through its engaging delivery style decodes the Law and legal jargon for a layperson and takes the user through common scenarios, educating them about the do’s and donts and the appropriate conduct at a workplace.";

const Courses = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const videoCount = useSelector((state) => state?.videoCount);
  const quizEpisode = useSelector((state) => state?.quizEpisode);
  const [localState, setLocalState] = useState(englishLang?.learner);
  const episodeCompleted = useSelector((state) => state?.episodeCompleted);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const selectLang = useSelector((state) => state?.selectLang);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const menuRefresh = useSelector((state) => state?.menuRefresh);
  const episodeLength = useSelector((state) => state?.episodeLength);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  const [activeColor, setActiveColor] = useState("");
  const [courseMenu, setCourseMenu] = useState([]);
  const [headMenuData, setHeadMenuData] = useState({});
  const [winners, setWinners] = useState({
    winner1: null,
    winner2: null,
    winner3: null,
    winner4: null,
  });
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDesc, setCourseDesc] = useState("");
  const [topRankItem, setTopRankItem] = useState([]);
  const [isChanging, setIsChanging] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isBadges, setIsBadges] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);
  const handleClose = (e) => {
    setIsOpen(e);
    setIsBadges(e);
  };

  const messagesEndRef = useRef(null);

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToTop, [videoCount]);

  const startLearning = async () => {
    setBannerLoading(true);
    let url = Episode.startLearning;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: episodeSelectLang,
      });
      setBannerLoading(false);
      if (data?.data) {
        // dispatch(setCompleteProgressBar(data?.data?.courseCompleteProgressBar));
        dispatch(setButtonName(data?.data?.button_name));
        dispatch(setEpisodeCompleted(data?.data?.COURSE_COMPLETED));
        let comData = {
          categorie: get(data?.data, "course_categorie", ""),
          courseTitle: get(data?.data, "course_name", ""),
          thumbUrl: get(data?.data, "course_baise_infos.thumbnailImage", ""),
          basicDescription: get(
            data?.data,
            "course_baise_infos.baiseDescription",
            ""
          ),
          client_logo: get(data?.data, "clientLogo", ""),
        };
        dispatch(setBannerThumb(comData));
        if (quizEpisode) {
          if (data?.data?.NEXT_EPISODE) {
            dispatch(setEpisodeId(data?.data?.episodeId));
            dispatch(setVideoPlay(true));
          }
        }
        if (data?.data?.VIEW_POLICY_DOCUMENTS) {
          navigate("/learner/courses/policy-document");
          dispatch(setEpisodeId(null));
          dispatch(setVideoPlay(false));
        }
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response && error.response.status === 400) {
        if (error.response.data?.data?.HOME_SCREEN) {
          dispatch(setVideoPlay(false));
        }
      }
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      setBannerLoading(false);
    }
  };
  const getCourseMenu = async () => {
    setMenuLoading(true);
    let url = Course.getCourseMenu;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: selectLang,
      });
      setMenuLoading(false);
      if (data?.data) {
        setHeadMenuData(data?.data);
        dispatch(setTemplateId(data?.data?.learnerCertificateId));
      }
      if (data?.result) {
        setCourseMenu(data?.result);
        dispatch(setCourseMenuData(data?.result));
        // setActiveColor(data?.result[0]?.key);
      }
    } catch (error) {
      setMenuLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  const getLeaderBoard = async () => {
    setMenuLoading(true);
    let url = Course.getLeaderBoard;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: selectLang,
      });
      setMenuLoading(false);

      if (data?.result) {
        setTopRankItem(data?.result);
        const winner1 = data?.result.find((item) => item.rank === 1);
        const winner2 = data?.result.find((item) => item.rank === 2);
        const winner3 = data?.result.find((item) => item.rank === 3);
        const winner4 = data?.result.find((item) => item.rank === 4);
        setWinners({ winner1, winner2, winner3, winner4 });
      } else {
        setTopRankItem([]);
      }
      if (data?.data) {
        setCourseTitle(data?.data?.courseTitle);
        setCourseDesc(data?.data?.title);
      }
    } catch (error) {
      setMenuLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };
  useEffect(() => {
    if (courseId && episodeSelectLang) {
      startLearning();
    }
  }, [courseId, episodeSelectLang]);

  useEffect(() => {
    if (courseId) {
      getCourseMenu();
      // getLeaderBoard();
    }
  }, [courseId, menuRefresh]);

  const getRoutes = (e) => {
    if (e === "course_content") {
      navigate(`${dynamicRoutes}/learner/courses-content`);
    }
    if (e === "quiz_result") {
      navigate(`${dynamicRoutes}/learner/courses/quiz-result`);
    }
    if (e === "feedback") {
      navigate(`${dynamicRoutes}/learner/courses/feedback `);
    }
    if (e === "policy_documents") {
      navigate(`${dynamicRoutes}/learner/courses/policy-document`);
    }
    if (e === "commitee") {
      navigate(`${dynamicRoutes}/learner/courses/committee`);
    }
    if (e === "certificate") {
      navigate(`${dynamicRoutes}/learner/courses/certificate`, {
        state: {
          templateId: headMenuData?.learnerCertificateId
            ? headMenuData?.learnerCertificateId
            : "",
        },
      });
    }
  };
  const getMenuName = (e) => {
    return selectLangJson[e];
    // if (e === "course_content") {
    //   navigate("/learner/courses-content");
    // }
    // if (e === "quiz_result") {
    //   navigate("/learner/courses/quiz-result");
    // }
    // if (e === "leaderboard") {
    //   setIsOpen(true);
    // }
    // if (e === "feedback") {
    //   navigate("/learner/courses/feedback");
    // }
    // if (e === "policy_documents") {
    //   navigate("/learner/courses/policy-document");
    // }
    // if (e === "commitee") {
    //   navigate("/learner/courses/committee");
    // }
    // if (e === "certificate") {
    //   navigate("/learner/courses/certificate");
    // }
  };
  const handleColor = () => {
    if (
      window.location.pathname === `${dynamicRoutes}/learner/courses-content`
    ) {
      setActiveColor("course_content");
    }
    if (
      window.location.pathname ===
      `${dynamicRoutes}/learner/courses/quiz-result`
    ) {
      setActiveColor("quiz_result");
    }
    if (
      window.location.pathname === `${dynamicRoutes}/learner/courses/feedback`
    ) {
      setActiveColor("feedback");
    }
    if (
      window.location.pathname ===
      `${dynamicRoutes}/learner/courses/policy-document`
    ) {
      setActiveColor("policy_documents");
    }
    if (
      window.location.pathname ===
      `${dynamicRoutes}/learner/courses/quiz-result`
    ) {
      setActiveColor("quiz_result");
    }
    if (
      window.location.pathname === `${dynamicRoutes}/learner/courses/committee`
    ) {
      setActiveColor("commitee");
    }
    if (
      window.location.pathname ===
      `${dynamicRoutes}/learner/courses/certificate`
    ) {
      setActiveColor("certificate");
    }
  };
  useEffect(() => {
    handleColor();
  }, [episodeCompleted, window.location.pathname]);
  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  console.log(episodeLength, "episodeLength episodeLength");

  const constProgressBar = () => {
    let count = 0;
    const length = episodeLength?.length;
    const markes = 100 / length;

    for (const el of episodeLength) {
      console.log(el, "El value");
      // if (el?.user_episode_details) {
      //   count += markes;
      // }
      // if (el?.assessmentQuiz?.user_quizzes) {
      //   count -= 5;
      //   if (el.assessmentQuiz.user_quizzes.passed) {
      //     count += 5;
      //   }
      // }
      if (el?.user_episode_details?.completeStatus) {
        console.log("condition 1");
        count += markes;
      }
      if (el?.assessmentQuiz && el?.user_episode_details?.completeStatus) {
        console.log("condition 2");
        count -= 5;
      }
      if (el?.assessmentQuiz?.user_quizzes?.passed) {
        console.log("condition 3");
        count += 5;
      }
    }

    console.log(`Progress: ${count}%`);
    dispatch(setCompleteProgressBar(count?.toFixed(2)));
  };

  useEffect(() => {
    if (episodeLength?.length) {
      constProgressBar();
    }
  }, [episodeLength]);
  return (
    <CSS.MainContainer themeColor={theme} isHeight={true}>
      <CSS.InnerContainer themeColor={theme}>
        <CSS.FirstContainer>
          <Sidebar
            theme={theme}
            navigate={navigate}
            dispatch={dispatch}
            localState={localState}
          />
        </CSS.FirstContainer>
        <CSS.SecondContainer themeColor={theme}>
          <Navbar
            navigate={navigate}
            dispatch={dispatch}
            theme={theme}
            localState={localState}
          />
          <CSS.ComContainer themeColor={theme}>
            <CS.CourseContainer>
              <CS.CourseHeader themeColor={theme}>
                <HeaderView
                  headData={headMenuData}
                  theme={theme}
                  handleBadges={setIsBadges}
                />
              </CS.CourseHeader>
              <CS.CourseBody>
                <CS.CourseInnerBody>
                  <div ref={messagesEndRef} />
                  <CS.BannerContainer>
                    {bannerLoading ? (
                      <Skeleton
                        width={"100%"}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={700}
                        style={{ borderRadius: "12px" }}
                      />
                    ) : (
                      <BannerVew
                        courseId={courseId}
                        theme={theme}
                        dispatch={dispatch}
                        handleIsChanging={setIsChanging}
                        isChanging={isChanging}
                        navigate={navigate}
                        handleCourseMenu={getCourseMenu}
                        dynamicRoute={dynamicRoutes}
                      />
                    )}
                  </CS.BannerContainer>
                  <CS.CourseContentBox>
                    <CS.FirstCourseContent>
                      <CS.InnerFirst themeColor={theme}>
                        {menuLoading ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <Skeleton
                              width={200}
                              baseColor={skeletonBaseColor}
                              highlightColor={skeletonHighlightColor}
                              height={45}
                              style={{ borderRadius: "12px" }}
                            />
                            <Skeleton
                              width={200}
                              baseColor={skeletonBaseColor}
                              highlightColor={skeletonHighlightColor}
                              height={45}
                              style={{ borderRadius: "12px" }}
                            />
                            <Skeleton
                              width={200}
                              baseColor={skeletonBaseColor}
                              highlightColor={skeletonHighlightColor}
                              height={45}
                              style={{ borderRadius: "12px" }}
                            />
                            <Skeleton
                              width={200}
                              baseColor={skeletonBaseColor}
                              highlightColor={skeletonHighlightColor}
                              height={45}
                              style={{ borderRadius: "12px" }}
                            />
                          </div>
                        ) : (
                          courseMenu?.map((val, ind) => {
                            return (
                              <CS.WatchButton
                                key={ind}
                                themeColor={theme}
                                activeColor={activeColor === val.key}
                                onClick={() => {
                                  if (val.key === "leaderboard") {
                                    getLeaderBoard();
                                    setIsOpen(true);
                                  } else {
                                    getRoutes(val.key);
                                    setActiveColor(val.key);
                                  }
                                }}
                              >
                                <p>{getMenuName(val.key)}</p>
                              </CS.WatchButton>
                            );
                          })
                        )}

                        {/* <CS.WatchButton
                          themeColor={theme}
                          activeColor={
                            window.location.pathname ===
                            "/learner/courses/quiz-result"
                          }
                          to="/learner/courses/quiz-result"
                        >
                          <p>{localState.quiz_result}</p>
                        </CS.WatchButton>
                        <CS.WatchButton
                          themeColor={theme}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          <p>{localState.leaderboard}</p>
                        </CS.WatchButton>
                        <CS.WatchButton
                          themeColor={theme}
                          activeColor={
                            window.location.pathname ===
                            "/learner/courses/feedback"
                          }
                          to="/learner/courses/feedback"
                        >
                          <p>{localState.feedback}</p>
                        </CS.WatchButton>
                        <CS.WatchButton
                          themeColor={theme}
                          activeColor={
                            window.location.pathname ===
                            "/learner/courses/policy-document"
                          }
                          to="/learner/courses/policy-document"
                        >
                          <p>{localState.policy_document}</p>
                        </CS.WatchButton>
                        <CS.WatchButton
                          themeColor={theme}
                          activeColor={
                            window.location.pathname ===
                            "/learner/courses/committee"
                          }
                          to="/learner/courses/committee"
                        >
                          <p>{localState.committee}</p>
                        </CS.WatchButton>
                        <CS.WatchButton
                          themeColor={theme}
                          activeColor={
                            window.location.pathname ===
                            "/learner/courses/certificate"
                          }
                          to="/learner/courses/certificate"
                        >
                          <p>{localState.certificate}</p>
                        </CS.WatchButton> */}
                      </CS.InnerFirst>
                      <Outlet />
                    </CS.FirstCourseContent>
                    <CS.SecondCourseContent>
                      <SidebarView theme={theme} />
                    </CS.SecondCourseContent>
                  </CS.CourseContentBox>
                </CS.CourseInnerBody>
              </CS.CourseBody>
              <Modal
                maxWidth={"500px"}
                isOpen={isOpen}
                // isClose={handleClose}
                backColor={colorProvider.darkBlue}
                component={
                  <MS.LeaderContainer themeColor={theme}>
                    <MS.LeaderHeader themeColor={theme}>
                      <MS.HeaderBox themeColor={theme}>
                        <p className="head-title">{courseTitle}</p>
                        <p className="head-desc">{courseDesc}</p>
                      </MS.HeaderBox>
                      <MS.CrossButton
                        onClick={() => {
                          handleClose(false);
                        }}
                      >
                        <img src={crossIcon} alt="" />
                      </MS.CrossButton>
                    </MS.LeaderHeader>
                    {topRankItem?.length > 0 ? (
                      <MS.TopRow>
                        {winners?.winner2 ? (
                          <MS.CommonTopper>
                            <MS.CustProfileTwo>
                              <img
                                src={winners?.winner2?.image || profileDemo}
                                alt=""
                              />
                            </MS.CustProfileTwo>
                            <MS.NumberBox>
                              <img src={numberTwo} alt="" />
                            </MS.NumberBox>
                            <MS.ProfileTitle>
                              {winners?.winner2?.name}
                            </MS.ProfileTitle>
                          </MS.CommonTopper>
                        ) : (
                          <MS.ComLearnerBoard></MS.ComLearnerBoard>
                        )}

                        <MS.CommonTopper>
                          <MS.Crown>
                            <img src={Crown_1_} alt="" />
                          </MS.Crown>
                          <MS.CustProfileGold>
                            <img
                              src={winners?.winner1?.image || profileDemo}
                              alt=""
                            />
                          </MS.CustProfileGold>
                          <MS.NumberBox>
                            <img src={numberOne} alt="" />
                          </MS.NumberBox>
                          <MS.ProfileMainTitle>
                            {winners?.winner1?.name}
                          </MS.ProfileMainTitle>
                        </MS.CommonTopper>
                        {winners?.winner3 ? (
                          <MS.CommonTopper>
                            <MS.CustProfileThree>
                              <img
                                src={winners?.winner3?.image || profileDemo}
                                alt=""
                              />
                            </MS.CustProfileThree>
                            <MS.NumberBox>
                              <img src={numberThree} alt="" />
                            </MS.NumberBox>
                            <MS.ProfileTitle>
                              {winners?.winner3?.name}
                            </MS.ProfileTitle>
                          </MS.CommonTopper>
                        ) : (
                          <MS.ComLearnerBoard></MS.ComLearnerBoard>
                        )}
                      </MS.TopRow>
                    ) : (
                      <MS.NoData>
                        <p>No Data</p>
                      </MS.NoData>
                    )}

                    <MS.UserList>
                      {/* {winners?.winner4 && (
                        <MS.UserRow activeColor={true}>
                          <MS.ProfileNameBox>
                            <img src={winners?.winner4?.image} alt="" />
                            <p>{winners?.winner4?.name}</p>
                          </MS.ProfileNameBox>
                          <MS.CicleNumber activeColor={true}>
                            <p>4</p>
                          </MS.CicleNumber>
                        </MS.UserRow>
                      )} */}
                      {topRankItem
                        .filter((item) => item.rank > 3)
                        .map((item, index) => (
                          <MS.UserRow activeColor={item.active} key={index}>
                            <MS.ProfileNameBox>
                              <img src={item?.image || profileDemo} alt="" />
                              <p>{item.name}</p>
                            </MS.ProfileNameBox>
                            <MS.CicleNumber activeColor={item.active}>
                              <p>{item.rank}</p>
                            </MS.CicleNumber>
                          </MS.UserRow>
                        ))}
                    </MS.UserList>
                  </MS.LeaderContainer>
                }
              />
              <Modal
                maxWidth={"950px"}
                isOpen={isBadges}
                backColor={colorProvider.darkBlue}
                theme={theme}
                component={<LearnerBadges theme={theme} />}
              />
            </CS.CourseContainer>
          </CSS.ComContainer>
        </CSS.SecondContainer>
      </CSS.InnerContainer>
    </CSS.MainContainer>
  );
};

export default Courses;
